import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp, Octagon } from 'lucide-react';

const ContentEditableInput = ({ onSend, isDarkMode, isQueryPending, cancelQuery }) => {
  const [content, setContent] = useState('');
  const [height, setHeight] = useState(48);
  const inputRef = useRef(null);

  const handleInput = () => {
    if (inputRef.current) {
      setContent(inputRef.current.innerText);
      adjustHeight();
    }
  };

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const scrollHeight = inputRef.current.scrollHeight;
      const newHeight = Math.min(Math.max(48, scrollHeight), 240);
      setHeight(newHeight);
      inputRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        if (height < 240) {
          return;
        } else {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
        if (content.trim()) {
          onSend(content);
          setContent('');
          setHeight(48);
          if (inputRef.current) {
            inputRef.current.innerText = '';
          }
        }
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current.innerText !== content) {
      const selection = window.getSelection();
      const range = document.createRange();
      inputRef.current.innerText = content;
      range.selectNodeContents(inputRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [content]);

  const containerStyle = {
    position: 'relative',
    marginBottom: '0px',
    width: '100%',
    maxWidth: '800px',
  };

  const inputStyle = {
    width: '100%',
    height: `${height}px`,
    minHeight: '48px',
    maxHeight: '240px',
    padding: '12px 50px 12px 20px',
    backgroundColor: isDarkMode ? '#2C2C2C' : '#F0F0F0',
    color: isDarkMode ? '#E0E0E0' : '#333333',
    border: '1px solid ' + (isDarkMode ? '#3A3A3A' : '#CCCCCC'),
    borderRadius: '24px',
    fontSize: '1em',
    lineHeight: '24px',
    overflow: height >= 240 ? 'auto' : 'hidden',
    outline: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    boxSizing: 'border-box',
    transition: 'height 0.1s ease-out',
  };

  const placeholderStyle = {
    position: 'absolute',
    top: '50%',
    left: '20px',
    transform: 'translateY(-50%)',
    color: isDarkMode ? '#666666' : '#999999',
    pointerEvents: 'none',
  };

  const buttonStyle = {
    position: 'absolute',
    right: '10px',
    bottom: '8px',
    backgroundColor: isQueryPending ? (isDarkMode ? '#FF79C6' : '#bd93f9') : (isDarkMode ? '#3498DB' : '#2980B9'),
    color: 'white',
    border: 'none',
    borderRadius: isQueryPending ? '12px' : '50%',
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div
        ref={inputRef}
        contentEditable
        style={inputStyle}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
      />
      {!content && <div style={placeholderStyle}>Send a message...</div>}
      <button
        onClick={isQueryPending ? cancelQuery : () => onSend(content)}
        style={buttonStyle}
      >
        {isQueryPending ? <Octagon size={18} /> : <ArrowUp size={18} />}
      </button>
    </div>
  );
};

export default ContentEditableInput;
