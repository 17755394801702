import React from 'react';

const SkeletonLoader = ({ isDarkMode, fullHeight = false }) => {
  const backgroundColor = isDarkMode ? '#3A3A3A' : '#E0E0E0';
  const shimmerColor = isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.8)';

  const containerStyle = {
    width: '100%',
    height: fullHeight ? '100%' : 'auto',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: fullHeight ? 'flex-start' : 'center',
    overflow: 'hidden',
  };

  const itemStyle = {
    height: '24px',
    marginBottom: '12px', // Reduced from 20px to create tighter spacing
    borderRadius: '4px',
    animation: 'shimmer 2s infinite linear, changeWidth 4s infinite ease-in-out',
    background: `linear-gradient(to right, ${backgroundColor} 0%, ${shimmerColor} 50%, ${backgroundColor} 100%)`,
    backgroundSize: '200% 100%',
  };

  // Increased number of bars to fill more vertical space
  const numberOfBars = fullHeight ? 20 : 5;

  return (
    <div style={containerStyle}>
      <style>
        {`
          @keyframes shimmer {
            0% { background-position: -200% 0; }
            100% { background-position: 200% 0; }
          }
          @keyframes changeWidth {
            0%, 100% { width: 50%; }
            50% { width: 100%; }
          }
        `}
      </style>
      {[...Array(numberOfBars)].map((_, index) => (
        <div
          key={index}
          style={{
            ...itemStyle,
            animation: `shimmer 2s infinite linear, changeWidth 4s infinite ease-in-out ${index * 0.1}s`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
