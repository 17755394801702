import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  CloudDownload,
  ChevronLeft,
  ChevronRight,
  Edit
} from 'lucide-react';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({
  serverError,
  isRightSidebarVisible,
  pdfBase64,
  currentPage,
  totalPages,
  fileName,
  isDarkMode,
  onDownload,
  onPageChange,
  currentReference,
  onReferenceUpdate
}) => {
  const [containerWidth, setContainerWidth] = useState(null);
  const [displayMode, setDisplayMode] = useState('filename');
  const [isEditingRef, setIsEditingRef] = useState(false);
  const [editedRef, setEditedRef] = useState(currentReference);
  const [pdfLoading, setPdfLoading] = useState(false);
  const containerRef = useRef(null);
  const pdfDocumentRef = useRef(null);

  const pdfUrl = useMemo(() => {
    if (!pdfBase64) return null;
    return `data:application/pdf;base64,${pdfBase64}`;
  }, [pdfBase64]);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setContainerWidth(width > 0 ? width - 40 : 650);
    }
  }, [isRightSidebarVisible]);

  useEffect(() => {
    setEditedRef(currentReference);
  }, [currentReference]);

  useEffect(() => {
    return () => {
      if (pdfDocumentRef.current) {
        pdfDocumentRef.current.destroy();
        pdfDocumentRef.current = null;
      }
    };
  }, [pdfBase64]);

  const handleReferenceUpdate = async () => {
    if (editedRef?.trim() !== currentReference?.trim()) {
      await onReferenceUpdate(editedRef.trim());
    }
    setIsEditingRef(false);
  };

  const handleDocumentLoadSuccess = (pdf) => {
    pdfDocumentRef.current = pdf;
    setPdfLoading(false);
  };

  const handleDocumentLoadError = (error) => {
    console.error('PDF load error:', error);
    setPdfLoading(false);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginTop: '-40px',
      backgroundColor: isDarkMode ? '#252525' : '#F0F0F0',
      color: isDarkMode ? '#E0E0E0' : '#333333',
      position: 'relative'
    },
    topControlsContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10
    },
    leftControls: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginTop: '-15px'
    },
    pdfContainer: {
      flex: 1,
      overflow: 'hidden',
      padding: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      height: 'calc(100vh - 240px)',
      alignItems: 'center',
      marginTop: '10px',
      marginBottom: '60px'
    },
    bottomContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderTop: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
      backgroundColor: isDarkMode ? '#252525' : '#F0F0F0',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '40px',
      marginBottom: '10px'
    },
    displayToggle: {
      display: 'flex',
      gap: '0.5rem'
    },
    textDisplay: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      justifyContent: 'flex-end',
      flex: 1,
      marginLeft: '1rem',
    },
    iconButton: {
      background: 'none',
      border: 'none',
      color: isDarkMode ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      padding: '0.25rem',
      borderRadius: '4px',
      transition: 'color 0.2s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    pageText: {
      fontSize: '0.875rem',
      whiteSpace: 'nowrap'
    },
    editContainer: {
      display: 'flex',
      gap: '0.5rem',
      flex: 1,
      maxWidth: '400px',
      marginLeft: 'auto'
    },
    input: {
      flex: 1,
      padding: '0.25rem 0.5rem',
      backgroundColor: isDarkMode ? '#3d3d3d' : '#FFFFFF',
      color: isDarkMode ? '#E0E0E0' : '#333333',
      border: `1px solid ${isDarkMode ? '#666666' : '#CCCCCC'}`,
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '1.5',
      width: '100%'
    },
    saveButton: {
      padding: '0.25rem 0.75rem',
      backgroundColor: isDarkMode ? '#FF79C6' : '#bd93f9',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer'
    },
    toggleButton: (isActive) => ({
      padding: '0.25rem 0.75rem',
      backgroundColor: isActive 
        ? (isDarkMode ? '#FF79C6' : '#bd93f9')
        : 'transparent',
      color: isActive
        ? 'white'
        : isDarkMode ? '#E0E0E0' : '#333333',
      border: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
      borderRadius: '4px',
      cursor: 'pointer'
    }),
    loadingText: {
      textAlign: 'center',
      padding: '2rem',
      color: isDarkMode ? '#E0E0E0' : '#333333'
    }
  };

  if (serverError) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-red-500">Error: {serverError}</div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.topControlsContainer}>
        <div style={styles.leftControls}>
          <button
            onClick={() => onPageChange(-1)}
            disabled={currentPage <= 1}
            style={{
              ...styles.iconButton,
              opacity: currentPage <= 1 ? 0.5 : 1,
              cursor: currentPage <= 1 ? 'not-allowed' : 'pointer'
            }}
          >
            <ChevronLeft size={28} />
          </button>
          <span style={styles.pageText}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => onPageChange(1)}
            disabled={currentPage >= totalPages}
            style={{
              ...styles.iconButton,
              opacity: currentPage >= totalPages ? 0.5 : 1,
              cursor: currentPage >= totalPages ? 'not-allowed' : 'pointer'
            }}
          >
            <ChevronRight size={28} />
          </button>
        </div>
      </div>

      <div ref={containerRef} style={styles.pdfContainer}>
        {pdfUrl ? (
          <Document
            file={pdfUrl}
            onLoadSuccess={handleDocumentLoadSuccess}
            onLoadError={handleDocumentLoadError}
            loading={<div style={styles.loadingText}>Loading PDF...</div>}
            noData={<div style={styles.loadingText}>No PDF file selected.</div>}
            error={<div style={styles.loadingText}>Error loading PDF!</div>}
          >
            <Page
              key={`page_${currentPage}`}
              pageNumber={currentPage}
              width={containerWidth ? Math.min(containerWidth - 40, 550) : 500}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              loading=""
            />
          </Document>
        ) : (
          <div style={styles.loadingText}>No PDF to display.</div>
        )}
      </div>

      <div style={styles.bottomContainer}>
        <div style={styles.displayToggle}>
          <button 
            onClick={() => setDisplayMode('filename')}
            style={styles.toggleButton(displayMode === 'filename')}
          >
            File
          </button>
          <button
            onClick={() => setDisplayMode('reference')}
            style={styles.toggleButton(displayMode === 'reference')}
          >
            Ref
          </button>
        </div>
        
        <div style={styles.bottomContainer}>
        <div style={styles.displayToggle}>
          <button 
            onClick={() => setDisplayMode('filename')}
            style={styles.toggleButton(displayMode === 'filename')}
          >
            File
          </button>
          <button
            onClick={() => setDisplayMode('reference')}
            style={styles.toggleButton(displayMode === 'reference')}
          >
            Ref
          </button>
        </div>
        
        <div style={styles.textDisplay}>
          {displayMode === 'filename' ? (
            <span>{fileName}</span>
          ) : isEditingRef ? (
            <div style={styles.editContainer}>
              <input
                type="text"
                value={editedRef}
                onChange={(e) => setEditedRef(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleReferenceUpdate()}
                style={styles.input}
              />
              <button onClick={handleReferenceUpdate} style={styles.saveButton}>
                Save
              </button>
            </div>
          ) : (
            <>
              <span>{currentReference || 'No reference available'}</span>
              {displayMode === 'reference' && (
                <button 
                  onClick={() => setIsEditingRef(true)}
                  style={styles.iconButton}
                  title="Edit reference"
                >
                  <Edit size={16} />
                </button>
              )}
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default PDFViewer;