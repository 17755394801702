import React, { useState, useRef, useEffect } from 'react';
import { Plus, X, Play, Edit, FileText, Check, GripVertical, Copy } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoadingIndicator from './LoadingIndicator';
import SkeletonLoader from './SkeletonLoader';

const API_BASE_URL = "http://localhost:5000";
const API_ENDPOINT = `${API_BASE_URL}/api/query`;

const BatchQueriesPopup = ({ 
  isOpen, 
  onClose, 
  isDarkMode,
  handleDocumentClick,
  addToClipboard,
  kLimit,
  isFilteredSearch,
  filteredFileName
}) => {
  const [queries, setQueries] = useState([{ 
    id: 1, 
    text: '', 
    response: null,
    isLoading: false,
    order: 0
  }]);
  const [editingResponse, setEditingResponse] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [exportClicked, setExportClicked] = useState(false);
  const [copyStates, setCopyStates] = useState({});
  const [responseWidths, setResponseWidths] = useState({});
  const [buttonStates, setButtonStates] = useState({
    runAll: false,
    addQuery: false,
    export: false,
    copyAll: false
  });
  const [clickedDocument, setClickedDocument] = useState({ queryId: null, docId: null });
  const runButtonRefs = useRef({});
  const textareaRef = useRef(null);
  const responseRefs = useRef({});

  const COPY_FEEDBACK_DURATION = 500;
  const ANIMATION_DURATION = 800;
  const BUTTON_FEEDBACK_DURATION = 300;

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
  
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }
  
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const handleButtonClick = (buttonName, action) => {
    setButtonStates(prev => ({ ...prev, [buttonName]: true }));
    action();
    setTimeout(() => {
      setButtonStates(prev => ({ ...prev, [buttonName]: false }));
    }, BUTTON_FEEDBACK_DURATION);
  };

  const addQuery = () => {
    const newId = queries.length > 0 ? Math.max(...queries.map(q => q.id)) + 1 : 1;
    const newOrder = queries.length;
    setQueries([...queries, { 
      id: newId, 
      text: '', 
      response: null, 
      isLoading: false,
      order: newOrder
    }]);
  };

  const removeQuery = (id) => {
    const removedQuery = queries.find(q => q.id === id);
    setQueries(queries
      .filter(query => query.id !== id)
      .map(query => ({
        ...query,
        order: query.order > removedQuery.order 
          ? query.order - 1 
          : query.order
      }))
    );
  };

  const updateQueryText = (id, text) => {
    setQueries(queries.map(query => 
      query.id === id ? { ...query, text } : query
    ));
  };

  const runAllQueries = async () => {
    const sortedQueries = [...queries].sort((a, b) => a.order - b.order);
    for (const query of sortedQueries) {
      if (query.text.trim()) {
        await runQuery(query.id);
      }
    }
  };

  const runQuery = async (id) => {
    const query = queries.find(q => q.id === id);
    if (!query?.text.trim()) return;
  
    setQueries(prev => prev.map(q =>
      q.id === id ? { ...q, isLoading: true } : q
    ));
  
    try {
      const endpoint = isFilteredSearch
        ? `${API_BASE_URL}/api/query_prefilter`
        : `${API_BASE_URL}/api/batch_query`;  // Use new batch_query endpoint
  
      const requestBody = {
        query: query.text,
        kLimit,
        ...(isFilteredSearch && filteredFileName && {
          filename_filter: filteredFileName.replace('.pdf', '')
        })
      };
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Process the response
      let combinedText = '';
      let combinedIdMapping = {};
      let combinedReferences = {};
  
      if (data.results && Array.isArray(data.results)) {
        data.results.forEach(result => {
          // For batch queries, we expect a single, well-formatted result
          combinedText = result.system_response;
          Object.assign(combinedIdMapping, result.id_mapping || {});
          Object.assign(combinedReferences, result.references || {});
        });
      }
  
      setQueries(prev => prev.map(q =>
        q.id === id
          ? {
              ...q,
              response: {
                text: combinedText,
                idMapping: combinedIdMapping,
                references: combinedReferences,
                context_alt: data.context_alt || ''
              },
              isLoading: false
            }
          : q
      ));
    } catch (error) {
      console.error("Error in runQuery:", error);
      setQueries(prev => prev.map(q =>
        q.id === id
          ? {
              ...q,
              response: {
                text: `Error: ${error.message}`,
                idMapping: {},
                references: {},
                context_alt: ''
              },
              isLoading: false
            }
          : q
      ));
    }
  };

  const MessageFormatter = ({ text, idMapping, references, isDarkMode, queryId }) => {
    if (!text) return null;
    
    const paragraphs = text.split('\n\n');
    return (
      <>
        {paragraphs.map((paragraph, pIndex) => {
          const parts = paragraph.split(/([(\（][Ss]ource:\s*[^)\）]+[)\）])/g);
          return (
            <p key={pIndex} style={{ marginBottom: '1em' }}>
              {parts.map((part, partIndex) => {
                if (part.match(/^[(\（][Ss]ource:/)) {
                  const docIds = [...part.matchAll(/doc_\d+/g)]
                    .map(match => match[0])
                    .filter(Boolean);
  
                  return (
                    <span key={partIndex} style={{ 
                      display: 'inline',
                      marginLeft: '0px',
                      marginRight: '0px'
                    }}>
                      {docIds.map((docId, idx) => {
                        const isClicked = clickedDocument.queryId === queryId && 
                                        clickedDocument.docId === docId;
                        const docRef = references && references[docId];
                        const labelToShow = (docRef && docRef.trim() !== '') ? docRef : docId;
                        
                        return (
                          <React.Fragment key={`${docId}-${idx}`}>
                            <FileText
                              size={16}
                              onClick={() => {
                                setClickedDocument({ queryId, docId });
                                handleDocumentClick(docId, idMapping);
                              }}
                              style={{
                                color: isClicked 
                                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                                  : (isDarkMode ? '#3498DB' : '#2980B9'),
                                cursor: 'pointer',
                                marginLeft: '2px',
                                marginRight: '2px',
                                verticalAlign: 'middle',
                                position: 'relative',
                                top: '-1px',
                                transition: 'color 0.3s ease'
                              }}
                              title={`View document ${labelToShow}`}
                            />
                            {idx < docIds.length - 1 && <span style={{ margin: '0 1px' }}/>}
                          </React.Fragment>
                        );
                      })}
                    </span>
                  );
                }
                return part;
              })}
            </p>
          );
        })}
      </>
    );
  };

  const handleCopy = (queryId, text = '') => {
    let contentToCopy = text;
    const query = queries.find(q => q.id === queryId);
    const references = query?.response?.references || {};
    
    if (text) {
      contentToCopy = text.replace(
        /\([Ss]ource:\s*(doc_\d+)\)/g, 
        (match, docId) => {
          const reference = references[docId];
          return reference ? `(${reference})` : match;
        }
      );
    }
    
    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        if (addToClipboard) {
          addToClipboard(contentToCopy);
        }
        setCopyStates(prev => ({ ...prev, [queryId]: true }));
        setTimeout(() => {
          setCopyStates(prev => ({ ...prev, [queryId]: false }));
        }, COPY_FEEDBACK_DURATION);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const handleExport = () => {
    const sortedQueries = [...queries]
      .sort((a, b) => a.order - b.order);

    const content = sortedQueries
      .map(query => {
        let responseText = query.response?.text || 'No response yet';
        const references = query.response?.references || {};
        
        // Replace doc_ids with references in the export
        responseText = responseText.replace(
          /\([Ss]ource:\s*(doc_\d+)\)/g, 
          (match, docId) => {
            const reference = references[docId];
            return reference ? `(${reference})` : match;
          }
        );
        
        return `Query: ${query.text}\n\nResponse: ${responseText}\n\n---\n\n`;
      })
      .join('\n');

    const blob = new Blob([content], { type: 'application/msword' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'batch_queries_report.doc';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    setExportClicked(true);
    setTimeout(() => setExportClicked(false), ANIMATION_DURATION);
  };

  const copyAllText = () => {
    const sortedQueries = [...queries]
      .sort((a, b) => a.order - b.order);

    const content = sortedQueries
      .map(query => {
        let responseText = query.response?.text || 'No response yet';
        const references = query.response?.references || {};
        
        responseText = responseText.replace(
          /\([Ss]ource:\s*(doc_\d+)\)/g, 
          (match, docId) => {
            const reference = references[docId];
            return reference ? `(${reference})` : match;
          }
        );
        
        return `Query: ${query.text}\n\nResponse: ${responseText}\n\n---\n\n`;
      })
      .join('\n');

    navigator.clipboard.writeText(content)
      .then(() => {
        if (addToClipboard) {
          addToClipboard(content);
        }
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const startEditing = (queryId) => {
    const query = queries.find(q => q.id === queryId);
    const responseElement = responseRefs.current[queryId];
    if (responseElement) {
      setResponseWidths(prev => ({
        ...prev,
        [queryId]: responseElement.offsetWidth
      }));
    }
    setEditingResponse(queryId);
    setEditedText(query?.response?.text || '');
  };

  const saveEdit = (queryId) => {
    setQueries(queries.map(query => 
      query.id === queryId ? {
        ...query,
        response: query.response ? {
          ...query.response,
          text: editedText
        } : {
          text: editedText,
          idMapping: {},
          references: {}
        }
      } : query
    ));
    setEditingResponse(null);
    setEditedText('');
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(queries);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setQueries(updatedItems);
  };

  const renderResponseContainer = (query) => {
    const containerStyle = {
      paddingTop: '12px',
      paddingLeft: '18px',
      paddingRight: '18px',
      paddingBottom: '12px',
      borderRadius: '6px',
      marginRight: '18px',
      border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
      backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
      fontSize: '0.95rem',
      lineHeight: '1.6',
      position: 'relative',
      minHeight: '150px'
    };

    const actionButtonsStyle = {
      position: 'absolute',
      top: '12px',
      right: '12px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      backgroundColor: 'transparent'
    };

    const actionButtonStyle = (isActive = false) => ({
      background: 'transparent',
      border: 'none',
      padding: '4px',
      cursor: 'pointer',
      color: isActive 
        ? (isDarkMode ? '#FF79C6' : '#bd93f9')
        : (isDarkMode ? '#666666' : '#999999'),
      transition: 'color 0.2s ease',
      '&:hover': {
        color: isDarkMode ? '#FF79C6' : '#bd93f9'
      }
    });

    if (query.isLoading) {
      return (
        <div style={containerStyle}>
          <SkeletonLoader 
            isDarkMode={isDarkMode}
            fullHeight={false}
          />
          <div style={actionButtonsStyle}>
            <button style={actionButtonStyle()} disabled>
              <Edit size={16} />
            </button>
            <button style={actionButtonStyle()} disabled>
              <Copy size={16} />
            </button>
          </div>
        </div>
      );
    }

    if (editingResponse === query.id) {
      return (
        <div style={containerStyle}>
          <textarea
            ref={textareaRef}
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            style={{
              width: '100%',
              minHeight: '150px',
              padding: '12px',
              paddingRight: '40px',
              borderRadius: '6px',
              border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
              backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
              color: isDarkMode ? '#E0E0E0' : '#333333',
              fontSize: '0.95rem',
              lineHeight: '1.6',
              fontFamily: "'Aptos', sans-serif",
              resize: 'vertical',
              boxSizing: 'border-box'
            }}
          />
          <button
            onClick={() => saveEdit(query.id)}
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              background: isDarkMode ? '#FF79C6' : '#bd93f9',
              border: 'none',
              borderRadius: '4px',
              padding: '4px 8px',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            <Check size={16} />
          </button>
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <div style={{ paddingRight: '40px' }}>
          {query.response ? (
            <MessageFormatter 
              text={query.response.text}
              idMapping={query.response.idMapping}
              references={query.response.references}
              isDarkMode={isDarkMode}
              queryId={query.id}
            />
          ) : (
            <div style={{
              color: isDarkMode ? '#666666' : '#999999',
              fontStyle: 'italic',
              fontSize: '0.95rem'
            }}>
              The answer to your query will appear here...
            </div>
          )}
        </div>
        <div style={actionButtonsStyle}>
          <button
            onClick={() => startEditing(query.id)}
            style={actionButtonStyle(false)}
          >
            <Edit size={16} />
          </button>
          <button
            onClick={() => handleCopy(query.id, query.response?.text || '')}
            style={actionButtonStyle(copyStates[query.id])}
          >
            {copyStates[query.id] ? <Check size={16} /> : <Copy size={16} />}
          </button>
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  const sortedQueries = [...queries].sort((a, b) => a.order - b.order);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
      fontFamily: "'Aptos', sans-serif"
    }}>
      <div style={{
        width: '90%',
        height: '85%',
        backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: `2px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`
      }}>
        {/* Header */}
        <div style={{
          padding: '16px',
          borderBottom: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <h2 style={{
              margin: 0,
              color: isDarkMode ? '#FFFFFF' : '#000000',
              fontSize: '1.25rem',
              fontWeight: '600',
              paddingLeft: '10px',
              paddingRight: '40px'
            }}>
              Report Generator
            </h2>
            <button
              onClick={() => handleButtonClick('runAll', runAllQueries)}
              style={{
                background: buttonStates.runAll ? 
                  (isDarkMode ? '#D94C83' : '#8758FF') : 
                  (isDarkMode ? '#FF79C6' : '#bd93f9'),                
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '0.95rem',
                transition: 'all 0.2s ease'
              }}
            >
              <Play size={16} /> Run All
            </button>
            <button
              onClick={() => handleButtonClick('addQuery', addQuery)}
              style={{
                background: buttonStates.addQuery ? 
                  (isDarkMode ? '#444444' : '#E5E5E5') : 'none',
                border: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                borderRadius: '4px',
                padding: '8px 16px',
                color: isDarkMode ? '#FF79C6' : '#bd93f9',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '0.95rem',
                transition: 'all 0.2s ease'
              }}
            >
              <Plus size={16} /> Add Query
            </button>
            <button
              onClick={() => handleButtonClick('export', handleExport)}
              style={{
                background: buttonStates.export ? 
                  (isDarkMode ? '#444444' : '#E5E5E5') : 'none',
                border: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                borderRadius: '4px',
                padding: '8px 16px',
                color: exportClicked || buttonStates.export
                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                  : (isDarkMode ? '#E0E0E0' : '#333333'),
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '0.95rem',
                transition: 'all 0.15s ease'
              }}
            >
              {exportClicked ? <Check size={16} /> : <FileText size={16} />}
              <span>{exportClicked ? 'Exported!' : 'Export'}</span>
            </button>
            <button
              onClick={() => handleButtonClick('copyAll', copyAllText)}
              style={{
                background: buttonStates.copyAll ? 
                  (isDarkMode ? '#444444' : '#E5E5E5') : 'none',
                border: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                borderRadius: '4px',
                padding: '8px 16px',
                color: isDarkMode ? '#FF79C6' : '#bd93f9',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '0.95rem',
                transition: 'all 0.2s ease'
              }}
            >
              <Copy size={16} /> Copy All
            </button>
          </div>
          <button
            onClick={onClose}
            style={{
              background: 'none',
              border: 'none',
              color: isDarkMode ? '#FF79C6' : '#bd93f9',
              cursor: 'pointer'
            }}
          >
            <X size={24} />
          </button>
        </div>

        {/* Main Content - Draggable Queries */}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="queries">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  padding: '16px'
                }}
              >
                {sortedQueries.map((query, index) => (
                  <Draggable
                    key={query.id}
                    draggableId={query.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          display: 'grid',
                          gridTemplateColumns: '20px 300px 1fr',
                          gap: '16px',
                          marginBottom: '24px',
                          opacity: snapshot.isDragging ? 0.9 : 1
                        }}
                      >
                        <div
                          {...provided.dragHandleProps}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: isDarkMode ? '#666666' : '#999999',
                            cursor: 'grab'
                          }}
                        >
                          <GripVertical size={20} />
                        </div>

                        <div style={{
                          position: 'relative',
                          display: 'flex',
                          gap: '8px'
                        }}>
                          <textarea
                            value={query.text}
                            onChange={(e) => updateQueryText(query.id, e.target.value)}
                            placeholder="Enter your query..."
                            style={{
                              flex: 1,
                              padding: '16px',
                              borderRadius: '6px',
                              border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
                              backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
                              color: isDarkMode ? '#E0E0E0' : '#333333',
                              resize: 'vertical',
                              minHeight: '80px',
                              fontSize: '0.95rem',
                              lineHeight: '1.6',
                              fontFamily: "'Aptos', sans-serif"
                            }}
                          />
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px'
                          }}>
                            <button
                              ref={el => runButtonRefs.current[query.id] = el}
                              onClick={() => runQuery(query.id)}
                              disabled={query.isLoading}
                              style={{
                                background: 'none',
                                border: 'none',
                                padding: '8px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                color: isDarkMode ? '#FF79C6' : '#bd93f9'
                              }}
                            >
                              <Play size={20} />
                            </button>
                            <button
                              onClick={() => removeQuery(query.id)}
                              style={{
                                background: 'none',
                                border: 'none',
                                padding: '8px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                color: isDarkMode ? '#666666' : '#999999'
                              }}
                            >
                              <X size={20} />
                            </button>
                          </div>
                        </div>

                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px'
                        }}>
                          {renderResponseContainer(query)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default BatchQueriesPopup;