import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = ({ isDarkMode }) => {
  const { loginWithRedirect } = useAuth0();

  const buttonStyle = {
    backgroundColor: isDarkMode ? '#3498DB' : '#2980B9',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  };

  return (
    <button
      onClick={() => loginWithRedirect()}
      style={buttonStyle}
      onMouseOver={(e) => e.target.style.backgroundColor = isDarkMode ? '#2980B9' : '#3498DB'}
      onMouseOut={(e) => e.target.style.backgroundColor = isDarkMode ? '#3498DB' : '#2980B9'}
    >
      Log In
    </button>
  );
};

export default LoginButton;
