import React, { useState, useMemo } from 'react';
import { Copy, X, FileText, Download, Check, ChevronDown, ChevronUp, Search, Trash } from 'lucide-react';

const ClipboardPanel = ({
  isOpen,
  clipboardItems,
  onRemoveItem,
  onClearAll,
  isDarkMode,
  onClose
}) => {
  const [copyAllClicked, setCopyAllClicked] = useState(false);
  const [exportClicked, setExportClicked] = useState(false);
  const [copiedItems, setCopiedItems] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [matchCount, setMatchCount] = useState(0);

  const ANIMATION_DURATION = 800;

  // Move useMemo before any conditional returns
  const filteredAndHighlightedItems = useMemo(() => {
    if (!searchTerm.trim()) {
      setMatchCount(0);
      return clipboardItems;
    }

    const searchRegExp = new RegExp(searchTerm, 'gi');
    let totalMatches = 0;

    const filtered = clipboardItems.filter(item => {
      const matches = (item.text.match(searchRegExp) || []).length;
      totalMatches += matches;
      return matches > 0;
    });

    setMatchCount(totalMatches);
    return filtered;
  }, [clipboardItems, searchTerm]);

  if (!isOpen) return null;

  const highlightText = (text) => {
    if (!searchTerm.trim()) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase()
        ? <mark
            key={i}
            style={{
              backgroundColor: isDarkMode ? '#FF79C6' : '#bd93f9',
              color: isDarkMode ? '#1E1E1E' : '#FFFFFF',
              padding: '0.1em 0.2em',
              borderRadius: '2px'
            }}
          >
            {part}
          </mark>
        : part
    );
  };

  const handleCopyAll = () => {
    const allText = clipboardItems
      .map(item => item.text)
      .join('\n\n---\n\n');
    navigator.clipboard.writeText(allText);

    setCopyAllClicked(true);
    setTimeout(() => setCopyAllClicked(false), ANIMATION_DURATION);
  };

  const handleExportWord = () => {
    const content = clipboardItems
      .map(item => item.text)
      .join('\n\n---\n\n');

    const blob = new Blob([content], { type: 'application/msword' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'clipboard_export.doc';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    setExportClicked(true);
    setTimeout(() => setExportClicked(false), ANIMATION_DURATION);
  };

  const handleCopyItem = (index) => {
    navigator.clipboard.writeText(clipboardItems[index].text);
    setCopiedItems(prev => ({ ...prev, [index]: true }));
    setTimeout(() => {
      setCopiedItems(prev => ({ ...prev, [index]: false }));
    }, ANIMATION_DURATION);
  };

  const toggleExpand = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Rest of the component remains the same...
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '80px',
        left: '20px',
        width: '300px',
        maxHeight: '60vh',
        backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Header */}
      <div style={{
        padding: '12px',
        borderBottom: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div style={{
            color: isDarkMode ? '#E0E0E0' : '#333333',
            fontWeight: 700,
            fontSize: '14px',
            minWidth: '85px'
          }}>
            Clipboard
          </div>
          <div style={{
            display: 'flex',
            gap: '4px'
          }}>
            {/* Copy All Button */}
            <button
              onClick={handleCopyAll}
              style={{
                backgroundColor: copyAllClicked ? (isDarkMode ? '#1a1a1a' : '#f0f0f0') : 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '4px 6px',
                borderRadius: '4px',
                color: copyAllClicked
                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                  : (isDarkMode ? '#E0E0E0' : '#333333'),
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                fontSize: '12px',
                transition: 'all 0.15s ease',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                minWidth: '72px'
              }}
              title="Copy all items"
            >
              {copyAllClicked ? <Check size={14} /> : <Copy size={14} />}
              <span style={{ fontSize: '11px' }}>{copyAllClicked ? 'Copied!' : 'Copy All'}</span>
            </button>

            {/* Export Button */}
            <button
              onClick={handleExportWord}
              style={{
                backgroundColor: exportClicked ? (isDarkMode ? '#1a1a1a' : '#f0f0f0') : 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '4px 6px',
                borderRadius: '4px',
                color: exportClicked
                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                  : (isDarkMode ? '#E0E0E0' : '#333333'),
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                fontSize: '12px',
                transition: 'all 0.15s ease',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                minWidth: '65px'
              }}
              title="Export to Word"
            >
              {exportClicked ? <Check size={14} /> : <FileText size={14} />}
              <span style={{ fontSize: '11px' }}>{exportClicked ? 'Exported!' : 'Export'}</span>
            </button>

            {/* Clear All Button */}
            <button
              onClick={onClearAll}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '4px',
                borderRadius: '4px',
                color: isDarkMode ? '#E0E0E0' : '#333333',
                transition: 'all 0.15s ease',
                display: 'flex',
                alignItems: 'center'
              }}
              title="Clear clipboard"
            >
              <Trash size={14} />
            </button>

            {/* Close Button */}
            <button
              onClick={onClose}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '4px',
                borderRadius: '4px',
                color: isDarkMode ? '#E0E0E0' : '#333333',
                transition: 'all 0.15s ease',
                display: 'flex',
                alignItems: 'center'
              }}
              title="Close clipboard"
            >
              <X size={14} />
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div style={{
          position: 'relative',
          marginTop: '4px'
        }}>
          <Search
            style={{
              position: 'absolute',
              left: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: isDarkMode ? '#666666' : '#999999'
            }}
            size={14}
          />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search in clipboard..."
            style={{
              width: '100%',
              padding: '6px 28px 6px 28px',
              borderRadius: '4px',
              border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
              backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
              color: isDarkMode ? '#FFFFFF' : '#000000',
              fontSize: '11px',
              boxSizing: 'border-box'
            }}
          />
          {searchTerm.trim() && (
            <div style={{
              position: 'absolute',
              right: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '11px',
              color: isDarkMode ? '#FF79C6' : '#bd93f9'
            }}>
              {matchCount} {matchCount === 1 ? 'match' : 'matches'}
            </div>
          )}
        </div>
      </div>

      {/* Clipboard Items */}
      <div style={{
        overflowY: 'auto',
        padding: '8px',
        flex: 1
      }}>
        {filteredAndHighlightedItems.length === 0 ? (
          <div style={{
            padding: '12px',
            textAlign: 'center',
            color: isDarkMode ? '#666666' : '#999999',
            fontSize: '0.9em'
          }}>
            {clipboardItems.length === 0 ? 'No items in clipboard' : 'No matches found'}
          </div>
        ) : (
          filteredAndHighlightedItems.map((item, index) => (
            <div
              key={index}
              style={{
                padding: '8px',
                marginBottom: '8px',
                backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
                borderRadius: '4px',
                border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
                position: 'relative'
              }}
            >
              <div style={{
                fontSize: '0.9em',
                color: isDarkMode ? '#E0E0E0' : '#333333',
                marginBottom: '4px',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                maxHeight: expandedItems[index] ? 'none' : '150px',
                overflow: 'hidden',
                position: 'relative',
                transition: 'max-height 0.15s ease'
              }}>
                {highlightText(item.text)}
                {!expandedItems[index] && item.text.length > 150 && (
                  <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '30px',
                    background: `linear-gradient(transparent, ${isDarkMode ? '#1E1E1E' : '#F8F8F8'})`,
                    pointerEvents: 'none'
                  }}/>
                )}
              </div>
              {item.text.length > 150 && (
                <button
                  onClick={() => toggleExpand(index)}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    color: isDarkMode ? '#666666' : '#999999',
                    fontSize: '0.8em',
                    padding: '2px 4px',
                    marginBottom: '4px',
                    borderRadius: '4px',
                    transition: 'color 0.15s ease'
                  }}
                >
                  {expandedItems[index] ? (
                    <>
                      <ChevronUp size={14} />
                      <span>Show less</span>
                    </>
                  ) : (
                    <>
                      <ChevronDown size={14} />
                      <span>Show more</span>
                    </>
                  )}
                </button>
              )}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '4px'
              }}>
                <div style={{
                  fontSize: '0.8em',
                  color: isDarkMode ? '#666666' : '#999999'
                }}>
                  {new Date(item.timestamp).toLocaleTimeString()}
                </div>
                <div style={{
                  display: 'flex',
                  gap: '4px'
                }}>
                  <button
                    onClick={() => handleCopyItem(index)}
                    style={{
                      backgroundColor: copiedItems[index] ? (isDarkMode ? '#1a1a1a' : '#f0f0f0') : 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '4px',
                      borderRadius: '4px',
                      color: copiedItems[index]
                        ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                        : (isDarkMode ? '#666666' : '#999999'),
                      transition: 'all 0.15s ease'
                    }}
                    title="Copy item"
                  >
                    {copiedItems[index] ? <Check size={14} /> : <Copy size={14} />}
                  </button>
                  <button
                    onClick={() => onRemoveItem(index)}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '4px',
                      borderRadius: '4px',
                      color: isDarkMode ? '#666666' : '#999999',
                      transition: 'all 0.15s ease'
                    }}
                    title="Remove item"
                  >
                    <X size={14} />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ClipboardPanel;