import React, { useRef, useEffect, useState } from 'react';
import { X, Copy, Send } from 'lucide-react';
import SkeletonLoader from './SkeletonLoader';

const HelpPopup = ({ isOpen, onClose, content, isDarkMode, isLoading, onSendQuery, onCopy }) => {
  const popupRef = useRef(null);
  const [localClickedIcons, setLocalClickedIcons] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleCopy = (text, index) => {
    const iconKey = `copy-${index}`;
    setLocalClickedIcons(prev => ({
      ...prev,
      [iconKey]: true
    }));

    navigator.clipboard.writeText(text)
      .then(() => {
        if (onCopy) {
          onCopy(text);
        }
        setTimeout(() => {
          setLocalClickedIcons(prev => ({
            ...prev,
            [iconKey]: false
          }));
        }, 300);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        setLocalClickedIcons(prev => ({
          ...prev,
          [iconKey]: false
        }));
      });
  };

  const handleSend = (question) => {
    onSendQuery(question, true);  // Add a second parameter to indicate this is from help popup
    onClose();
  };

  if (!isOpen) return null;

  const styles = {
    contextPopupOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    contextPopup: {
      backgroundColor: isDarkMode ? '#2C2C2C' : '#FFFFFF',
      borderRadius: '10px',
      width: '80%',
      maxWidth: '600px',
      maxHeight: '85%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    contextPopupHeader: {
      padding: '15px 20px',
      borderBottom: '1px solid ' + (isDarkMode ? '#3A3A3A' : '#E0E0E0'),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    contextPopupTitle: {
      margin: 0,
      color: isDarkMode ? '#E0E0E0' : '#333333',
    },
    contextPopupCloseButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#E0E0E0' : '#333333',
    },
    contextPopupContent: {
      padding: '20px',
      overflowY: 'auto',
    },
    questionContainer: {
      marginBottom: '15px',
    },
    contextPopupParagraph: {
      margin: '0 0 10px 0',
      color: isDarkMode ? '#E0E0E0' : '#333333',
    },
    questionActions: {
      display: 'flex',
      gap: '10px',
    },
    actionButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#FF79C6' : '#BD93F9', // Pink color
      transition: 'color 0.3s',
    },
    sendButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#FF79C6' : '#BD93F9', // Pink color
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <div style={styles.contextPopupOverlay}>
      <div ref={popupRef} style={styles.contextPopup}>
        <div style={styles.contextPopupHeader}>
          <h2 style={styles.contextPopupTitle}>Potential Follow-up Questions</h2>
          <button style={styles.contextPopupCloseButton} onClick={onClose}>
            <X size={24} />
          </button>
        </div>
        <div style={styles.contextPopupContent}>
          {isLoading ? (
            <SkeletonLoader isDarkMode={isDarkMode} />
          ) : (
            content.map((question, index) => (
              <div key={index} style={styles.questionContainer}>
                <p style={styles.contextPopupParagraph}>{question}</p>
                <div style={styles.questionActions}>
                  <button
                    style={{
                      ...styles.actionButton,
                      color: localClickedIcons[`copy-${index}`] ? '#3498DB' : (isDarkMode ? '#FF79C6' : '#BD93F9')
                    }}
                    onClick={() => handleCopy(question, index)}
                    aria-label="Copy question"
                  >
                    <Copy size={16} />
                  </button>
                  <button
                    style={styles.sendButton}
                    onClick={() => handleSend(question)}
                    aria-label="Send question"
                  >
                    <Send size={16} />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpPopup;
