import React, { useState, useEffect, useRef } from 'react';
import { Check, X, Edit } from 'lucide-react';

const EditableMessage = ({ 
  text, 
  isEditing, 
  onSave, 
  onCancel, 
  isDarkMode,
  onEdit
}) => {
  const [editedText, setEditedText] = useState(text);
  const [isHovering, setIsHovering] = useState(false);
  const textareaRef = useRef(null);
  const displayRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);

  useEffect(() => {
    if (isEditing && displayRef.current) {
      setContainerWidth(displayRef.current.offsetWidth);
      setContainerHeight(displayRef.current.offsetHeight);
    }
  }, [isEditing]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editedText]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSave(editedText);
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  const commonStyles = {
    width: '100%',
    minHeight: '36px',
    padding: '10px 15px',
    backgroundColor: isDarkMode ? '#3498DB' : '#2980B9',
    color: 'white',
    borderRadius: '15px 15px 0 15px',
    fontSize: '1em',
    lineHeight: '1.4',
    fontFamily: 'inherit',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  };

  const styles = {
    container: {
      position: 'relative',
      width: containerWidth || '100%',
      minWidth: '200px',
      marginBottom: '32px'
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    display: {
      ...commonStyles,
      width: '100%',
    },
    textarea: {
      ...commonStyles,
      width: '100%',
      border: `1px solid ${isDarkMode ? '#2980B9' : '#216694'}`,
      outline: 'none',
      resize: 'none',
      overflowY: 'auto',
      maxHeight: '300px',
    },
    editButton: {
      position: 'absolute',
      left: '-30px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#FF79C6' : '#bd93f9',
      padding: '5px',
      borderRadius: '5px',
      transition: 'opacity 0.2s ease',
      opacity: isHovering ? 1 : 0,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      position: 'absolute',
      bottom: '-32px',
      right: '0',
      zIndex: 1
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      padding: 0
    },
    saveButton: {
      backgroundColor: isDarkMode ? '#3498DB' : '#2980B9',
      color: 'white'
    },
    cancelButton: {
      backgroundColor: isDarkMode ? '#E74C3C' : '#C0392B',
      color: 'white'
    }
  };

  if (isEditing) {
    return (
      <div style={styles.container}>
        <textarea
          ref={textareaRef}
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          onKeyDown={handleKeyDown}
          style={styles.textarea}
          autoFocus
        />
        <div style={styles.buttonsContainer}>
          <button
            onClick={() => onSave(editedText)}
            style={{...styles.button, ...styles.saveButton}}
            title="Save"
          >
            <Check size={14} />
          </button>
          <button
            onClick={onCancel}
            style={{...styles.button, ...styles.cancelButton}}
            title="Cancel"
          >
            <X size={14} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={displayRef}
      style={styles.wrapper}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <button
        onClick={onEdit}
        style={styles.editButton}
        title="Edit message"
      >
        <Edit size={16} />
      </button>
      <div style={styles.display}>
        {text}
      </div>
    </div>
  );
};

export default EditableMessage;